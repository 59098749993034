import Loading from '@common_loaders/Backdrop';
import Alert from '@material-ui/lab/Alert';

const Copyright = (props) => {
    const {
        t, loading, error, storeConfig,
    } = props;
    if (error) {
        return (
            <Alert className="m-15" severity="error">
                {t('common:error:fetchError')}
            </Alert>
        );
    }
    if (loading) return <Loading open={loading} />;
    return (
        <div className="copyright">
            <span>{storeConfig.copyright}</span>
            <style jsx global>
                {`
                    .copyright {
                        text-align: center;
                        background-color: #272727;
                        padding: 10px;
                        height: 50px;
                        line-height: 2;
                    }
                    .copyright span {
                        color: #989898;
                        letter-spacing: 0px;
                        text-align: center;
                        font-size: 14px;
                    }

                    @media only screen and (max-width: 767px) {
                        .copyright {
                            height: 146px;
                        }

                        .copyright span {
                            font-size: 12px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default Copyright;
